
import React, { useContext, useEffect, useState } from "react"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container.jsx";
import MainWrap from "@components/Wrap/MainWrap";
import Grid from "@material-ui/core/Grid";
import ButtonBack from "@components/Buttons/ButtonBack.jsx"
import IconButton from "@components/Buttons/IconButton.jsx"
import { CardMedia, Typography } from "@material-ui/core";
import { makeComponentStyles } from "@components/mixins.js"
import Markdown from 'markdown-to-jsx';
import clsx from 'clsx';
import { useIntl, navigate } from "gatsby-plugin-react-intl";
import { getStrapiContentByLang } from '../../heplers';
import { format, utcToZonedTime } from "date-fns-tz";
import Seo from "@components/seo";
import { GET_COMMENT_ORDER, MAKE_COMMENT_ORDER } from "@api/modules/requests";
import Avatar from '@material-ui/core/Avatar';
import AuthContext from '@src/auth/context';
import { FormGroup, FormControl, Form, FormFeedback } from "@components/Forms";
import required from "@rules/required.js";
import max from "@rules/max.js";
import rule from '@validator/rule';
import Send from "@static/images/Send.svg";
import { observer } from "mobx-react-lite";
import { FacebookShareButton, VKShareButton, TwitterShareButton } from "react-share";
import TW from "@static/images/social/Twitter.svg"
import FB from "@static/images/social/FaceBook.svg"
import VK from "@static/images/social/VK.svg"
import CP from "@static/images/copy_link.svg"

import { SnackbarProvider, useSnackbar } from 'notistack';


const styles = ({ palette, breakpoints }) => ({
    text: {
        listStylePosition: 'inside',
        overflow: 'hidden',
        fontSize: "18px",
        [breakpoints.down('sm')]: {
            fontSize: "14px",
        }
    },
    dates: {
        color: palette.primary.main
    },
    line: {
        border: 'none',
        color: palette.primary.main,
        backgroundColor: palette.primary.main,
        height: '2px'
    },
    line_margin: {
        margin: '15px 0'
    },
    commentTitle: {
        fontSize: '20px',
        fontWeight: 500,
        [breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    Form_input: {
        background: palette.primary.light,
        display: 'flex',
    },
    input: {
        height: '100%',
        border: 'none !important',
        background: 'transparent',
        "&::placeholder": {
            color: palette.primary.main
        }
    },
    margin_bottom_none: {
        marginBottom: "0 !important"
    },
    btnSend: {
        borderRadius: '3px',
        height: '100%',
        margin: '5px 7px'
    },
    grid_padding: {
        padding: '8px 0 !important',
        width: '100%'
    },
    usercard: {
        display: 'flex',
        alignItems: 'center'
    },
    sharebtn: {
        marginRight: '15px'
    }
});

const initBody = {
    message: "",
    postID: ""
}

function ClipboardCoping() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const handleCoping = () => {
        navigator.clipboard.writeText(typeof window !== 'undefined' ? window.location.href : null);
        enqueueSnackbar(intl.formatMessage({ id: 'copied' }), { variant: 'success' });
    }
    return (
        <React.Fragment>
            <IconButton onClick={handleCoping}>
                <img src={CP} />
            </IconButton>
        </React.Fragment>
    )
}

function Show({ pageContext }) {
    const { text, dates, line, commentTitle, input, Form_input, margin_bottom_none, btnSend, line_margin, grid_padding, usercard, sharebtn } = makeComponentStyles(styles);
    const intl = useIntl();
    const payload = getStrapiContentByLang(pageContext.payload, intl.locale);
    const { PlaybillsSEO } = payload;
    const { auth } = useContext(AuthContext);
    initBody.postID = payload.strapiId;
    const ThisPageUri = typeof window !== 'undefined' ? window.location.href : null;
    const [body, setBody] = useState(initBody);
    const [comments, setComments] = useState([]);
    const [trigger, setTrigger] = useState(true)

    useEffect(async () => {
        try {
            const { data } = await GET_COMMENT_ORDER(payload.strapiId);
            setComments(data);
        } catch (e) { console.log(e) }
    }, [trigger]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!body.message.trim()) return;
        try {
            body.message = body.message.trim();
            await MAKE_COMMENT_ORDER(body);
            body.message = ''
            setTrigger(!trigger);
        } catch (e) { console.log(e) }
    }

    const observeBody = (key, event) => {
        const newBody = {
            ...body,
            [key]: event.target.value
        }
        setBody(newBody);
    }

    return (
        <>
            <Seo title={PlaybillsSEO?.TitleSEO}
                description={PlaybillsSEO?.DescriptionSEO}
                keywords={PlaybillsSEO?.KeywordsSEO?.split(',')}
            />
            <Layout>
                <Container>
                    <MainWrap>
                        <MainWrap.Header>
                            <ButtonBack click={() => navigate('/playbills')} />
                            <Typography component={"h1"} variant={"h2"}>{payload.Title}</Typography>
                            <Typography className={clsx(text, dates)}>
                                {format(utcToZonedTime(new Date(payload.DateStart)), 'dd.MM.yyyy, HH:mm')}
                                {` - `}
                                {format(utcToZonedTime(new Date(payload.DateEnd)), 'dd.MM.yyyy, HH:mm')}
                            </Typography>
                        </MainWrap.Header>
                        <MainWrap.Body>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <CardMedia
                                        component="img"
                                        image={payload.Image.localFile.publicURL}
                                        alt={payload.Image.name}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography className={text}>
                                        <Markdown>{payload.Content}</Markdown>
                                    </Typography>
                                </Grid>
                                <hr className={clsx(line, line_margin)} width='100%' />
                                <VKShareButton className={sharebtn} url={ThisPageUri}>
                                    <IconButton><img src={VK} /></IconButton>
                                </VKShareButton>
                                <FacebookShareButton className={sharebtn} url={ThisPageUri}>
                                    <IconButton><img src={FB} /></IconButton>
                                </FacebookShareButton>
                                <TwitterShareButton className={sharebtn} url={ThisPageUri}>
                                    <IconButton><img src={TW} /></IconButton>
                                </TwitterShareButton>
                                <SnackbarProvider preventDuplicate >
                                    <ClipboardCoping />
                                </SnackbarProvider>
                                <Grid className={grid_padding} item sx={12}>
                                    <Typography className={commentTitle}>{intl.formatMessage({ id: 'comments' })}:</Typography>
                                    {
                                        !auth.isAuth
                                        ? <Typography variant={"h4"} >{intl.formatMessage({ id: 'notAuthForComments' })}</Typography>
                                        : <Form className={Form_input} onSubmit={handleSubmit} validate>
                                            <FormGroup name={"comment-input"} className={margin_bottom_none}>
                                                <FormControl className={input}
                                                    placeholder={intl.formatMessage({ id: 'yourComment' })}
                                                    type="message"
                                                    value={body.message}
                                                    onChange={observeBody.bind(null, "message")}
                                                    validations={[required, rule(max, 500)]}
                                                />
                                                <FormFeedback />
                                            </FormGroup>
                                            <IconButton type='submit' className={btnSend} color='blue'><img src={Send} alt="Send" /></IconButton>
                                        </Form>
                                    }
                                </Grid>
                                {
                                    comments.map(({ message, datetime, profile }, key) => (
                                        <>
                                            <Grid className={grid_padding} item xs={12} key={key}>
                                                <div className={usercard}>
                                                    <Avatar style={{ marginRight: '16px' }} alt={profile.name} src={profile.avatar ?? ' '} />
                                                    <div>
                                                        <Typography>{profile.name} {profile.surname}</Typography>
                                                        <Typography style={{ fontSize: '14px' }}>{format(utcToZonedTime(new Date(datetime * 1000)), 'dd.MM.yyyy, HH:mm')}</Typography>
                                                    </div>
                                                </div>
                                                <Typography>{message}</Typography>
                                            </Grid>
                                            <hr className={line} width='100%' />
                                        </>
                                    ))
                                }
                            </Grid>
                        </MainWrap.Body>
                    </MainWrap>
                </Container>
            </Layout>
        </>

    )
}

export default observer(Show);
